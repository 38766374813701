import IUser from "../types";

export type Action = { type: "SET_USER"; payload: IUser } | { type: "CLEAR_USER" };

export const initialState: IUser = {
  username: "",
  password: "",
  roles: [],
  firstName: "",
  lastName: ""
};

export function userReducer(state: IUser, action: Action): IUser {
  switch (action.type) {
    case "SET_USER":
      return { ...state, ...action.payload };
    case "CLEAR_USER":
      return initialState;
    default:
      return state;
  }
}
