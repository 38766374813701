import React, { createContext, useReducer } from "react";
import MainRouter from "./routers";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./App.less";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const initialState = {
  user: null,
  loading: false,
  error: null
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    case "CLEAR_USER":
      return { ...state, user: null };
    default:
      return state;
  }
};

const initContext: any[] = [];
export const AppContext = createContext(initContext);

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ApolloProvider client={client}>
      <AppContext.Provider value={[state, dispatch]}>
        <MainRouter></MainRouter>
      </AppContext.Provider>
    </ApolloProvider>
  );
}

export default App;
