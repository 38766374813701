import { lazy } from "react";

export interface IRouter {
  component?: React.FunctionComponent;
  path?: string;
  exact?: boolean;
  children?: Array<IRouter>;
  layout?: React.FunctionComponent | React.ComponentClass;
  role?: string;
}

export const routerApp: IRouter[] = [
  {
    component: lazy(() => import("../pages/upload/UploadPage")),
    path: "/",
    exact: true
  },
  {
    component: lazy(() => import("../pages/upload/UploadPage")),
    path: "/upload",
    exact: true
  },
  // {
  //   component: lazy(() => import("../pages/login")),
  //   path: "/login",
  //   exact: true,
  //   layout: BlankLayout
  // },
  // {
  //   component: lazy(() => import("../pages/register")),
  //   path: "/register",
  //   exact: true,
  //   layout: BlankLayout
  // },
  // {
  //   component: lazy(() => import("../pages/verify/VerifyPage")),
  //   path: "/verify/:token",
  //   exact: true,
  //   layout: BlankLayout
  // },
  // {
  //   exact: true,
  //   layout: BasicLayout,
  //   path: "/",
  //   children: [
  //     {
  //       component: lazy(() => import("../pages/team/UserDetail")),
  //       path: "/team/:teamId/users/:userId",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/team/User")),
  //       path: "/team/:teamId/users",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/team/NewApp")),
  //       path: "/team/:teamId/apps/new-app",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/team/NewAppBundle")),
  //       path: "/team/:teamId/apps/new-app-bundle",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/team/AppDetail")),
  //       path: "/team/:teamId/apps/:id",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/team/Apps")),
  //       path: "/team/:teamId/apps",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/team/Team")),
  //       path: "/team/:teamId",
  //       role: Role.admin
  //     },
  //     {
  //       component: lazy(() => import("../pages/profile")),
  //       path: "/profile",
  //       role: Role.user
  //     },
  //     {
  //       component: lazy(() => import("../pages/home")),
  //       path: "/",
  //       exact: true
  //     }
  //   ]
  // },
  {
    component: lazy(() => import("../pages/not-found"))
  }
];
