import React from "react";
import "./CenterLayout.less";

export default function CenterLayout(props: any) {
  return (
    <div className="relative">
      <div className="relative-center">{props?.children}</div>
    </div>
  );
}
