import React from "react";
import CenterLayout from "../components/layouts/Center/CenterLayout";
import Loading from "../components/loading/Loading";

export default function Fallback() {
  return (
    <CenterLayout>
      <Loading />
    </CenterLayout>
  );
}
