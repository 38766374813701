import React from "react";
import { Spin } from "antd";
import "./Loading.less";
import { LoadingOutlined } from "@ant-design/icons";

function Loading() {
  return (
    <div className="content-loader">
      <div className="item-loader">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin translate={undefined} />} />
      </div>
    </div>
  );
}
export default React.memo(Loading);
