import React, { Suspense, useReducer } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { routerApp, IRouter } from "./Router";
import Fallback from "./Fallback";
import NotFoundPage from "../pages/not-found";
import RouterComponent from "./RouterComponent";
import { userReducer, initialState } from "../reducers/user.reducer";

function MainRouter() {
  // const history = useHistory();
  const [user] = useReducer(userReducer, initialState);

  const isError = false;
  return (
    <BrowserRouter>
      {isError ? (
        <Route component={NotFoundPage} />
      ) : (
        <Suspense fallback={<Fallback />}>
          <Switch>
            {routerApp
              .filter(f => !f.role || (f.role && user.roles.includes(f.role)))
              .map((route: IRouter, index: number) =>
                route.layout ? (
                  <Route key={index} path={route.path} component={() => <RouterComponent route={route} />} />
                ) : (
                  <Route key={index} path={route.path} component={route.component} />
                )
              )}
          </Switch>
        </Suspense>
      )}
    </BrowserRouter>
  );
}

export default MainRouter;
