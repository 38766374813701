import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { IRouter } from "./Router";
import Fallback from "./Fallback";

export default function RouterComponent({ route }: any) {
  return (
    <route.layout>
      {route.children ? (
        <Suspense fallback={<Fallback />}>
          <Switch>
            {route?.children?.map((child: IRouter, idx: number) => (
              <Route key={idx} exact={child.exact} path={child.path} component={child.component} />
            ))}
          </Switch>
        </Suspense>
      ) : (
        <route.component />
      )}
    </route.layout>
  );
}
